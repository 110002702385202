html {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/bg.webp") fixed;
  background-size:cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root, body, html {
  height: 100%;
}

@media (max-width: 575px) {
  /* xs < 576px */
  html {
    font-size: 10px !important;
  }
}

@media (min-width: 576px) {
  /* xs >= 576px */
  html {
    font-size: 11px !important;
  }
}

@media (min-width: 768px) {
  /* xs >= 768px */
  html {
    font-size: 12px !important;
  }
}

@media (min-width: 992px) {
  /* xs >= 992px */
  html {
    font-size: 13px !important;
  }
}

@media (min-width: 1200px) {
  /* xs >= 1200px */
  html {
    font-size: 16px !important;
  }
}

@media (min-width: 1600px) {
  /* xs >= 1600px */
  html {
    font-size: 18px !important;
  }
}